import { ReactElement, useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import App, { AppContext } from 'next/app';
import { useRouter } from 'next/router';

import AlertNotification from '../components/alerts/AlertNotification';
import ClaspBanner from '../components/alerts/ClaspBanner';
import ApplicationBackdrop from '../components/backdrop/ApplicationBackdrop';
import ConfirmationDialog from '../components/confirmations/confirmationDialog';
import DevOverlay from '../components/DevOverlay';
import EslApplicationReviewModal from '../components/esl/eslApplicationReviewModal';
import EslApplicationSubmittedModal from '../components/esl/eslApplicationSubmittedModal';
import ThemeWrapper from '../components/layout/ThemeWrapper';
import ConnectBankModal from '../components/modal/ConnectBankModal';
import ExternalLinkModal from '../components/modal/ExternalLinkModal';
import MethodFiModalContainer from '../components/modal/federalRepaymentModals/MethodFiModalContainer';
import SpinwheelModalContainer from '../components/modal/federalRepaymentModals/SpinwheelModalContainer';
import SponsorshipAgreementModalContainer from '../components/modal/sponsorshipAgreementModal/SponsorshipAgreementModalContainer';
import pageTitleConstants from '../constants/pageTitles';
import env from '../lib/env';
import triggerEvent from '../lib/triggerEvent';
import urlParamController from '../lib/UrlParamController';
import { AnalyticsDestination } from '../types/analytics';
import { PageWithLayout } from '../types/PageWithLayout';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: env.var.GTM_ID,
};

// eslint-disable-next-line no-restricted-globals
if (process.browser) {
  TagManager.initialize(tagManagerArgs);
}

if (typeof window !== 'undefined') { // checks that we are client-side
  // Check if DD Synthetic test, we want to avoid recording these sessions
  // Reference - https://docs.datadoghq.com/synthetics/guide/identify_synthetics_bots/?tab=browsertests
  const dataDogSyntheticTest = navigator.userAgent.includes('DatadogSynthetics');

  if (!dataDogSyntheticTest){   // Checks Posthog is enabled, and not DD
    posthog.init(env.var.POSTHOG_KEY, {
      api_host: env.var.POSTHOG_HOST || 'https://us.i.posthog.com',
      person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
      loaded: (posthog) => {
        if (env.var.NODE_ENV === 'development') posthog.debug(); // debug mode in development
      },
      autocapture: true,   // Keeping on for now, depending on level of noise we can turn this off
    });
  } else {
    console.log('Data Dog Synthetic test detected - skipping PostHog initialization.');
  }
}

const MyApp = ({
  Component,
  pageProps,
}: AppProps): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    document.title = pageTitleConstants.default;

    return urlParamController.handleSessionStorage();
  });

  useEffect(() => {
    triggerEvent('pageview', {
      'route': `${router.pathname}`,
    }, [AnalyticsDestination.GTM]);
  }, [router.pathname]);

  const getLayout = Component.getLayout || ((page: ReactElement) => page);

  return (
    <RecoilRoot>
      <ThemeWrapper>
        <PostHogProvider client={posthog}>
          {getLayout(<Component {...pageProps} />)}
          <DevOverlay />
          {/* TODO: move application modals entrypoint to a separate file */}
          <ConnectBankModal />
          <ExternalLinkModal />
          <AlertNotification />
          <SpinwheelModalContainer />
          <MethodFiModalContainer />
          <ConfirmationDialog />
          <EslApplicationReviewModal />
          <EslApplicationSubmittedModal />
          <SponsorshipAgreementModalContainer />
          <ApplicationBackdrop />
          <ClaspBanner />
        </PostHogProvider>
      </ThemeWrapper>
    </RecoilRoot>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

interface AppProps {
  Component: PageWithLayout;
  pageProps: anyObject;
}

export default MyApp;
