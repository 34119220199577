import { Loadable, useRecoilValueLoadable } from 'recoil';
import { useRouter } from 'next/router';

import { backendAuthenticatedAxios } from '../lib/backend';
import Form from '../lib/recoil/Form';
import { sessionAtom } from '../lib/recoil/persist';
import routes from '../lib/routes';
import triggerEvent from '../lib/triggerEvent';
import urlParamController from '../lib/UrlParamController';
import { AnalyticsDestination } from '../types/analytics';
import { QuoteRequestResponse } from '../types/responseSchema/quoteRequest';

import signUpForm from './forms/auth/signUpForm';
import universityEligibilityForm from './forms/universityElegibilityForm/form';
import { useUser } from './user';

/**
 * Stores the response data after submitting a quote request
 *
 * @note Use as the argument `responseState` in `Form.useSubmit()`
 */
export const quoteRequestApplicationResponse = sessionAtom<QuoteRequestResponse | null>({
  key: 'quoteRequestApplicationResponse',
  default: null,
});

/**
 * Returns the `response` state and a callback to `submit`
 *  the university quote request
 *
 * @returns [`response`, `submit`]
 */
const useQuoteRequestApplication = (redirectsOnSubmit = true): [
  Loadable<QuoteRequestResponse | null>,
  () => Promise<QuoteRequestResponse | null>,
] => {
  const router = useRouter();
  const user = useUser();

  /**
   * Holds a Recoil Loadable with the response
   *  returned by calling `submit()`
   */
  const response = useRecoilValueLoadable(quoteRequestApplicationResponse);

  /**
   * Sends a POST request to `quote/submit`
   *  with data from  `universityEligibilityForm` and `registerForm`;
   *  saves the response to recoil state.
   */
  const submit = Form.useSubmit(
    Form.join(universityEligibilityForm, signUpForm),
    async (formData) => {
      const utmData = urlParamController.getStorageData();

      // Check all relevant nullable fields are defined
      const isAllRequiredDataPresent = [
        formData.institution?.id,
        formData.fundingAmount,
        formData.usCitizenshipStatus?.code,
        formData.cip?.code,
        formData.credentialLevel?.code,
        formData.enrollmentStatus?.id,
        formData.stateOfResidence?.abbreviation,
      ].every((fieldValue) => !!fieldValue);

      if (!isAllRequiredDataPresent) {
        console.log('Form data has some field values missing:', formData);

        return null;
      }

      const requestData = {
        'institution':            formData.institution?.id,
        'graduation_date':        formData.graduationDate.toString().substring(0, 10),
        'funding_date':           formData.fundingDate.toString().substring(0, 10),
        'funding_amount':         formData.fundingAmount,
        'first_name':             formData.firstName || user.contents.firstName,
        'last_name':              formData.lastName || user.contents.lastName,
        'email_address':          formData.emailAddress || user.contents.email,
        'mobile_phone_number':    formData.mobilePhoneNumber || user.contents.mobileNumber,
        'mobile_consent':         formData.mobileConsent || user.contents.mobileConsent,
        'us_citizenship_status':  formData.usCitizenshipStatus?.code,
        'cip':                    formData.cip?.code,
        'credential_level':       formData.credentialLevel?.code,
        'enrollment_status':      formData.enrollmentStatus?.id,
        'gpa':                    formData.gpa,
        'state_of_residence':     formData.stateOfResidence?.abbreviation,
        'utm_data':               utmData,
      };

      console.log('Including saved UTM data:', utmData);
      console.log('Submitting university quote request', requestData);

      return await backendAuthenticatedAxios.post<QuoteRequestResponse | null>(
        'quote/submit',
        requestData,
      ).then((response) => {
        if (response.data) {
          const results = response.data.results;

          console.log('Received response to quote request', response);

          triggerEvent('apiResponse', {
            'api': 'pricing',
            'underwriteDecision': `${results.decision}`,
          }, [AnalyticsDestination.GTM]);

          // Redirect to the appropriate page
          if (redirectsOnSubmit) {
            switch (results.decision.toLowerCase()) {
              case 'underwrite':

                // Case statement does not include break so next case is executed
                // until break is found. Look up "Fall-through Behavior"
                // This is because we want the same behavior on
                // underwrite and manual cases.
                // eslint-disable-next-line no-fallthrough
              case 'manual queue':
                router.push(routes.internal.applyQuote.url)
                  .then(() => user.refresh());

                break;

              case 'rejected':
                router.push(routes.internal.partners.url);

                break;
              default:
                console.error('Decision returned an error:', response);
                router.push(routes.internal.error.url);

                break;
            }
          } else {
            console.error('Empty response:', response);
            router.push(routes.internal.error.url);
          }
        }

        return response.data;
      }).catch((error) => {
        console.error(error);
        if (redirectsOnSubmit)
          router.push(routes.internal.error.url);

        return null;
      });
    },
    quoteRequestApplicationResponse,
  );

  return [ response, submit ];
};

export default useQuoteRequestApplication;
