import { atom } from 'recoil';

import { MethodFIRepaymentModalSteps } from '../../types/methodFiModals';

const repaymentActiveModal = atom<MethodFIRepaymentModalSteps | null>({
  key: 'repaymentActiveModal',
  default: null,
});

export default repaymentActiveModal;
