import {
  PaletteColor,
  PaletteColorOptions,
  PaletteOptions as MuiPaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

interface PaletteOptions extends MuiPaletteOptions {
  pink: SimplePaletteColorOptions;
  yellow: SimplePaletteColorOptions;
  blue: PaletteColorOptions & {
    aqua: string;
    light: string;
  };
  green: PaletteColorOptions & {
    light: string;
  };
  purple: PaletteColorOptions & {
    bright: string;
    dark: string;
  };
  primary: PaletteColor & {
    pastelLight: string;
    pastelDark: string;
  };
  warning: {
    main: string;
  };
  error: {
    main: string;
    dark: string;
  };
  grey: ColorPartial & {
    A800: string;
    dark: string;
    light: string;
    neutral: string;
  };
  text: {
    primary: string;
    secondary: string;
  };
  info: {
    main: string;
    light: string;
  };
}

const palette: PaletteOptions = {
  pink: {
    main: '#EFB1B1',
    light: '#F7D8D8',
    contrastText: 'rgba(246, 125, 125, 0.2)',
    dark: '#EA3DAD',
  },
  yellow: {
    main: '#FDE26D',
    light: '#FEF1B8',
  },
  blue: {
    aqua: '#B8FBFF',
    light: '#E5FEFF',
  },
  green: {
    light: '#60E13247',
  },
  /**
   * @todo Conform to standard type `SimplePaletteColorOptions` where `bright` does not exist
   */
  purple: {
    bright: '#baafff',
    dark: '#8c7aff',
  },
  /**
   * @todo
   *  Move colors `pastelLight` and `pastelDark` out of `primary` palette
   */
  primary: {
    main: '#8c7aff',
    dark: '#7F58DE',
    light: '#F4EEFF',
    pastelLight: '#D4C1FA',
    pastelDark: '#44008B',
    contrastText: '#F2FFFF',
  },
  secondary: {
    main: '#051E27',
  },
  info: {
    main: '#E5FEFF',
    light: '#D6FEFF',
  },
  warning: {
    main: '#FCD31E',
  },
  error: {
    main: '#F77D7D',
    dark: '#C70039',
  },
  background: {
    default: '#FFFFFF',
  },
  text: {
    primary: '#303030',
    secondary: '#585858',
  },
  grey: {
    A400: '#BCBCBC',
    A700: '#D3D3D3',
    A800: '#F3F3F3',
    neutral: '#585858',
    light: '#D3D3D3',
    dark: '#585858',
  },
};

export default palette;
