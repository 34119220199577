import { Dispatch, FunctionComponent, SetStateAction, useCallback } from 'react';
import Image from 'next/image';
import {
  Box,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  StepperProps, SxProps,
} from '@mui/material';

import palette from '../../styles/palette';
import { Step as StepType, StepsRecord } from '../../types/Steps';

import checkPic from '../../public/checkbox/check-primary.svg';

export interface ProgressProps extends StepperProps{
  steps: StepsRecord;
  activeStep?: number;
  setActiveStep?: Dispatch<SetStateAction<StepType>>;
  connectorOptions?: { opacity: number } & SxProps;
  orientation?: 'horizontal' | 'vertical';
  showIncompleteSteps?: boolean;
  checkActiveStep?: boolean;
  incompleteStepsBold?: boolean;
  activeStepBold?: boolean;
  markPreviousStepsIncomplete?: boolean; // Flag to mark previous steps as incomplete
}

export interface StyledProgressProps extends ProgressProps {
  stepStyles?: any;
}

const Progress: FunctionComponent<StyledProgressProps> = ({
  activeStep = -1,
  setActiveStep,
  connectorOptions = null,
  orientation = 'horizontal',
  showIncompleteSteps = false,
  incompleteStepsBold = true,
  activeStepBold = true,
  checkActiveStep = false,
  steps,
  stepStyles,
  markPreviousStepsIncomplete = false,
  sx,
  alternativeLabel = false,
}) => {
  const connector = connectorOptions ? <StepConnector sx={connectorOptions} /> : null;

  const calculatedStepStyles = useCallback((step: StepType) => {
    const boldStyle = { fontWeight: 800 };

    const stepStyle = {
      color: '#9794A3',
      lineHeight: '18.2px',
      ...(stepStyles?.step ?? {}),
    };

    const activeStyle = {
      color: '#051E27',
      lineHeight: '18.2px',
      ...(stepStyles?.activeStep ?? {}),
    };

    let finalStyles = stepStyle;

    if (step.key === activeStep) {
      finalStyles = { ...finalStyles, ...activeStyle, ...(activeStepBold ? boldStyle : {}) };

      return finalStyles;
    }

    if (step.key > activeStep && incompleteStepsBold) {
      finalStyles = { ...finalStyles, ...(incompleteStepsBold ? boldStyle : {}) };

      return finalStyles;
    }

    return finalStyles;
  }, [ stepStyles, activeStep, incompleteStepsBold, activeStepBold ]);

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel={alternativeLabel}
      connector={connector}
      orientation={orientation}
      sx={sx}
    >
      {
        Object.values(steps).map((step) => (
          <Step key={step.key}>
            <StepLabel
              StepIconComponent={
                (props) => (
                  <StepIcon
                    {...props}
                    completed={
                      !markPreviousStepsIncomplete
                      && (step.key < activeStep || (checkActiveStep && step.key === activeStep))
                    }
                    showIncompleteSteps={showIncompleteSteps}
                    styles={stepStyles}
                  />
                )
              }
              onClick={() => setActiveStep && setActiveStep(step)}
            >
              <Box sx={calculatedStepStyles(step)}>
                {step.title}
              </Box>
            </StepLabel>
          </Step>
        ))
      }
    </Stepper>
  );
};

export type StepIconCustomProps = { showIncompleteSteps: boolean; styles: any };
export type StepIconComponentProps = StepIconProps & StepIconCustomProps;

const StepIcon: FunctionComponent<StepIconComponentProps> = ({
  completed,
  showIncompleteSteps,
  styles,
}) => {
  const stepIconBaseStyle = {
    alignContent: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '22px',
    justifyContent: 'center',
    marginRight: '12',
    width: '22px',
  };

  const checkedStyle = {
    backgroundColor: palette.primary.pastelLight,
  };

  const incompleteStyle = {
    backgroundColor: palette.grey.light,
  };

  // Extracting `image` before spreading the rest of `checkWrapper` styles
  const { image: checkWrapperImage = checkPic, ...checkWrapperStyles } = styles?.checkWrapper || {};

  return (
    <div>
      {
        completed
          ? (
            <div style={{ ...stepIconBaseStyle, ...checkedStyle, ...checkWrapperStyles }}>
              {checkWrapperImage && <Image alt='check' src={checkWrapperImage} />}
            </div>
          )
          : showIncompleteSteps
            ? (
              <div style={{ ...stepIconBaseStyle, ...incompleteStyle, ...(styles?.incompleteWrapper ?? {}) }} />
            )
            : null
      }
    </div>
  );
};

export default Progress;
