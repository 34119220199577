import { BRAND_COMPANY_NAME } from '../../constants/branding';
import { userRoles } from '../../constants/userRoles';

import permissionsOptions from './permissionsOptions';
import { InternalRoute, Routes } from './types';

// import applyIcon from '../public/sidebar/apply.svg';
import blogIcon from '../../public/sidebar/blog.svg';
import careerIcon from '../../public/sidebar/career.svg';
import checkboxIcon from '../../public/sidebar/checkbox.svg';
// eslint-disable-next-line import/no-duplicates
import dashboardIcon from '../../public/sidebar/dashboard.svg';
// import careerIcon from '../public/sidebar/career.svg';
// eslint-disable-next-line import/no-duplicates
import positionIcon from '../../public/sidebar/dashboard.svg';
import supportIcon from '../../public/sidebar/faq.svg';
import feedbackIcon from '../../public/sidebar/feedback.svg';
import moneyIcon from '../../public/sidebar/money.svg';
// import faqIcon from '../public/sidebar/faq.svg';
// import incomeIcon from '../public/sidebar/income.svg';
import paymentIcon from '../../public/sidebar/payment.svg';
// import applyActiveIcon from '../public/sidebar/primary/apply.svg';
import blogActiveIcon from '../../public/sidebar/primary/blog.svg';
import careerActiveIcon from '../../public/sidebar/primary/career.svg';
import checkboxActiveIcon from '../../public/sidebar/primary/checkbox.svg';
// eslint-disable-next-line import/no-duplicates
import dashboardActiveIcon from '../../public/sidebar/primary/dashboard.svg';
// import careerActiveIcon from '../../public/sidebar/primary/career.svg';
// eslint-disable-next-line import/no-duplicates
import positionActiveIcon from '../../public/sidebar/primary/dashboard.svg';
import supportActiveIcon from '../../public/sidebar/primary/faq.svg';
import feedbackActiveIcon from '../../public/sidebar/primary/feedback.svg';
import moneyActiveIcon from '../../public/sidebar/primary/money.svg';
// import faqActiveIcon from '../public/sidebar/primary/faq.svg';
// import incomeActiveIcon from '../public/sidebar/primary/income.svg';
import paymentActiveIcon from '../../public/sidebar/primary/payment.svg';
import signaturePenActiveIcon from '../../public/sidebar/primary/signaturepen.svg';
import trackChangesActiveIcon from '../../public/sidebar/primary/trackchanges.svg';
import signaturePenIcon from '../../public/sidebar/signaturepen.svg';
import trackChangesIcon from '../../public/sidebar/trackchanges.svg';

/** Creates an object with a checked & inferred type that extends `Routes` */
const createRoutes = <R extends Routes>(r: R): R => r;

export const isInternalRoute = (url: string): boolean => {
  return Object.values(routes.internal).some(
    (internalRoute: InternalRoute) => internalRoute.url === url,
  );
};

/**
 * Application routes definition (checked by Next.js middleware)
 * WARNING: This reference is loaded on build time so please avoid adding envars
 * here since those are not reloaded on application/container restart
 */

const routes = createRoutes({
  external: {
    changesToProgramOfferings: {
      url: 'https://www.clasp.com/changes-to-our-program-offerings',
    },
    bolsWages: {
      url: 'https://www.bls.gov/bls/wages.htm',
    },
    blog: {
      caption: 'Blog',
      url: 'https://www.clasp.com/media',
      permissions: permissionsOptions.all,
      icon: blogIcon,
      iconActive: blogActiveIcon,
    },
    support: {
      caption: 'Support',
      url: 'https://www.clasp.com/contact',
      permissions: permissionsOptions.all,
      icon: supportIcon,
      iconActive: supportActiveIcon,
    },
    campusdoor: {
      url: 'https://www.campusdoor.com/',
    },
    campusdoorApply: {
      url: 'https://www.campusdoor.com/stridefunding/productselection.aspx',
    },
    campusdoorLogin: {
      url: 'https://www.campusdoor.com/stridefunding/Login.aspx',
    },
    censusBureauACS: {
      url: 'https://www.census.gov/programs-surveys/acs',
    },
    campusDoorISLApplyUndergraduate: {
      url: 'https://www.campusdoor.com/stride/undergraduate/isl/qualify.aspx',
    },
    campusDoorISLApplyGraduate: {
      url: 'https://www.campusdoor.com/stride/graduate/isl/qualify.aspx',
    },
    campusDoorISLApplyDoctoral: {
      url: 'https://www.campusdoor.com/stride/doctoral/isl/qualify.aspx',
    },
    campusdoorCertificationPortal: {
      caption: 'Campus Door Certification',
      icon: checkboxIcon,
      iconActive: checkboxActiveIcon,
      url: 'https://www.campusdoor.com/campusconnectioncenter/Login/LoginMain.aspx',
    },
    certificationPortal: {
      caption: `${BRAND_COMPANY_NAME} Certification`,
      icon: checkboxIcon,
      iconActive: checkboxActiveIcon,
      url: 'https://origin.clasp.com/account/sign-in',
    },
    credible: {
      url: 'https://www.credible.com/',
    },
    doeScorecard: {
      url: 'https://collegescorecard.ed.gov/',
    },
    eligibility: {
      url: 'https://www.clasp.com/criteria',
    },
    eligibilityCriteria: {
      caption: 'Eligibility Criteria',
      icon: positionIcon,
      iconActive: positionActiveIcon,
      url: 'https://www.clasp.com/criteria',
    },
    federalAid: {
      url: 'https://studentaid.gov/',
    },
    getHelp: {
      url: 'https://clasp.com/gethelp',
    },
    knowledgeFinance: {
      url: 'https://www.knowledgefinance.com',
    },
    knowledgeFinanceStride: {
      url: 'https://stride.knowledgefinance.com',
    },
    launchStride: {
      url: 'https://stridefunding.launchservicing.com',
    },
    makePayment: {
      caption: 'Make Payment',
      url: 'https://stridefunding.launchservicing.com',
      permissions: permissionsOptions.all,
      icon: paymentIcon,
      iconActive: paymentActiveIcon,
    },
    nerdWallet: {
      url: 'https://www.nerdwallet.com/best/loans/student-loans/private-student-loans?trk=nw_gn2_4.0',
    },
    partners: {
      url: 'https://www.clasp.com/partners',
    },
    partnerLinkCredible: {
      url: 'https://www.credible.com/student-loans/?utm_source=stridefunding&utm_medium=referral&utm_campaign=private_student_loans',
    },
    partnerLinkFundingU: {
      url: 'https://www.funding-u.com/referrer/stridefunding?cm=&cn=Partners&cs=stridefunding&redirect_path=%2F&utm_campaign=Partners&utm_medium=&utm_source=stridefunding',
    },
    partnerLinkJuno: {
      url: 'https://joinjuno.com/p/sf?grow=stridef2',
    },
    partnerLinkLendGrow: {
      url: 'https://lendgrow.com/',
    },
    partnerLinkMPower: {
      url: 'https://www.mpowerfinancing.com/get-a-loan?utm_source=almapact&utm_medium=link&utm_content=04/29/2020--borrow',
    },
    privacy: {
      url: 'https://www.clasp.com/privacypolicy',
    },
    studentLoanHero: {
      url: 'https://studentloanhero.com/marketplace/private-student-loans/',
    },
    svaDtaAirtableQuiz: {
      url: 'https://airtable.com/shriEg3PK3I0834mQ',
    },
    svaServicerAirtableQuiz: {
      url: 'https://airtable.com/shr2FC0kYFLHa58VD',
    },
    terms: {
      url: 'https://www.clasp.com/termsofuse',
    },
    repaymentTermsFAQ: {
      caption: 'FAQ',
      url: 'https://www.clasp.com/repayment-user-agreement',
      permissions: permissionsOptions.customer,
      icon: supportIcon,
      iconActive: supportActiveIcon,
    },
  },
  internal: {
    accountForgot: {
      url: '/account/forgot',
      permissions: permissionsOptions.anonymous,
    },
    accountLogin: {
      url: '/account/login',
      permissions: permissionsOptions.anonymous,
    },
    accountLogout: {
      url: '/account/logout',
      permissions: permissionsOptions.all,
    },
    accountRegister: {
      url: '/account/register',
      permissions: permissionsOptions.anonymous,
    },
    accountReset: {
      url: '/account/reset',
      permissions: permissionsOptions.anonymous,
    },
    apiEnv: {
      url: '/api/dev/env',
      permissions: permissionsOptions.all,
    },
    apiHealth: {
      url: '/api/dev/health',
      permissions: permissionsOptions.all,
    },
    applyQuote: {
      caption: 'Quote',
      url: '/apply/quote',
      permissions: permissionsOptions.customer,
      icon: paymentIcon,
      iconActive: paymentActiveIcon,
    },
    altEdCalculators: {
      url: '/calculator/[fptUrlSlug]',
      permissions: permissionsOptions.all,
    },
    career: {
      url: '/career',
      permissions: permissionsOptions.all,
    },
    oldDashboard: {
      url: '/old-dashboard',
      permissions: permissionsOptions.all,
      caption: 'Old Dashboard',
      icon: dashboardIcon,
      iconActive: dashboardActiveIcon,
    },
    dashboard: {
      url: '/dashboard',
      permissions: [ userRoles.partner, userRoles.customer ],
      caption: 'Dashboard',
      icon: dashboardIcon,
      iconActive: dashboardActiveIcon,
    },
    devTrueWork: {
      url: '/dev/truework',
      permissions: permissionsOptions.all,
    },
    devProtected: {
      url: '/dev/protected',
      permissions: permissionsOptions.customer,
    },
    devWithdrawMethodConsent: {
      url: '/dev/method-consent-withdrawal',
      permissions: permissionsOptions.customer,
    },
    devBankConnect: {
      url: '/dev/bank-connect',
      permissions: permissionsOptions.all,
    },
    devBankModal: {
      url: '/dev/bank-modal',
      permissions: permissionsOptions.customer,
    },
    eligibilityAboutYou: {
      url: '/eligibility/university/about-you',
      permissions: permissionsOptions.all,
    },
    /** @todo Change permissions to anonymous only */
    eligibilityRegister: {
      url: '/eligibility/university/register',
      permissions: permissionsOptions.all,
    },
    eligibilityUniversityProgram: {
      caption: 'Eligibility Criteria',
      url: '/eligibility/university/program',
      permissions: permissionsOptions.all,
      icon: positionIcon,
      iconActive: positionActiveIcon,
    },
    employmentReport: {
      url: '/employment/report',
      permissions: permissionsOptions.all,
    },
    error: {
      url: '/error',
      permissions: permissionsOptions.all,
    },
    faq: {
      url: '/badpath',
      permissions: permissionsOptions.all,
    },
    finwiseSync: {
      caption: 'Finwise Sync',
      icon: checkboxIcon,
      iconActive: checkboxActiveIcon,
      url: '/staff/finwise-sync',
      permissions: permissionsOptions.staff,
    },
    changeRequest: {
      caption: 'Change Requests',
      icon: careerIcon,
      iconActive: careerActiveIcon,
      url: '/staff/change-requests',
      permissions: permissionsOptions.staff,
    },
    staffChangeRequestDetail: {
      url: '/staff/portal-change-requests/[portalChangeRequestId]',
      permissions: permissionsOptions.staff,
    },
    portalChangeRequest: {
      caption: 'Change Requests',
      icon: careerIcon,
      iconActive: careerActiveIcon,
      url: '/staff/portal-change-requests',
      permissions: permissionsOptions.staff,
    },
    disbursements: {
      caption: 'Disbursements',
      icon: moneyIcon,
      iconActive: moneyActiveIcon,
      url: '/staff/disbursements',
      permissions: permissionsOptions.staff,
    },
    uploadNachaRosters: {
      url: '/staff/upload-nacha-rosters',
      permissions: permissionsOptions.staff,
    },
    fundingAmount: {
      url: '/eligibility/university/funding',
      permissions: permissionsOptions.all,
    },
    getMoreFunding: {
      url: '/get-more-funding',
      permissions: permissionsOptions.all,
    },
    homepage: {
      url: '/',
      permissions: permissionsOptions.all,
    },
    income: {
      url: '/income/report',
      permissions: permissionsOptions.all,
    },
    partners: {
      url: '/partners',
      permissions: permissionsOptions.authenticated,
    },
    partnerReport: {
      caption: 'Reports',
      icon: positionIcon,
      iconActive: positionActiveIcon,
      url: '/partners/report',
      permissions: permissionsOptions.partner,
    },
    partnerChangeRequestOld: {
      caption: 'Submit Change Requests',
      icon: trackChangesIcon,
      iconActive: trackChangesActiveIcon,
      url: '/partners/change-requests',
      permissions: permissionsOptions.partner,
    },
    changeRequestNew: {
      caption: 'Submit Change Requests',
      icon: trackChangesIcon,
      iconActive: trackChangesActiveIcon,
      url: '/change-requests/new',
      permissions: permissionsOptions.partnerOrStaff,
    },
    partnerChangeRequestMaintenance: {
      caption: 'Change Requests',
      icon: trackChangesIcon,
      iconActive: trackChangesActiveIcon,
      url: '/partners/change-requests/maintenance',
      permissions: permissionsOptions.partner,
    },
    partnerViewChangeRequest: {
      caption: 'View Change Requests',
      icon: trackChangesIcon,
      iconActive: trackChangesActiveIcon,
      url: '/partners/view-change-requests',
      permissions: permissionsOptions.partner,
    },
    partnerSponsorshipApplicationDetail: {
      url: '/partners/employers/applications/[applicationId]',
      permissions: permissionsOptions.partner,
    },
    employerPartnerApplications: {
      caption: 'Applications',
      icon: checkboxIcon,
      iconActive: checkboxActiveIcon,
      url: '/partners/employers/applications',
      permissions: permissionsOptions.partner,
    },
    payment: {
      url: '/badpath',
      permissions: permissionsOptions.all,
    },
    position: {
      url: '/employment/report',
      permissions: permissionsOptions.all,
    },
    profile: {
      caption: 'Profile',
      icon: checkboxIcon,
      iconActive: checkboxActiveIcon,
      url: '/profile',
      permissions: permissionsOptions.authenticated,
    },
    refer: {
      url: '#refer-friend',
      permissions: permissionsOptions.all,
    },
    reportIncome: {
      url: '/income/report',
      permissions: permissionsOptions.all,
    },
    resources: {
      url: '/resources',
      permissions: permissionsOptions.all,
    },
    staffDashboard: {
      caption: 'Dashboard',
      icon: positionIcon,
      iconActive: positionActiveIcon,
      url: '/staff/dashboard',
      permissions: permissionsOptions.staff,
    },
    staffEmployerRepaymentManagement: {
      caption: 'Employer Repayment Management',
      icon: positionIcon,
      iconActive: positionActiveIcon,
      url: '/staff/repayment/manage',
      permissions: permissionsOptions.staff,
    },
    staffEmployerSponsorshipAppManagement: {
      caption: 'Sponsorship Applications',
      icon: checkboxIcon,
      iconActive: checkboxActiveIcon,
      url: '/staff/employers/applications',
      permissions: permissionsOptions.staff,
    },
    staffSponsorshipApplicationDetail: {
      url: '/staff/employers/applications/[applicationId]',
      permissions: permissionsOptions.staff,
    },
    studentFeedback: {
      caption: 'Feedback',
      icon: feedbackIcon,
      iconActive: feedbackActiveIcon,
      url: '/feedback',
      permissions: permissionsOptions.customer,
    },
    terms: {
      caption: 'Terms',
      icon: signaturePenIcon,
      iconActive: signaturePenActiveIcon,
      permissions: permissionsOptions.customer,
      url: '/terms',
    },
    welcome: {
      url: '/welcome',
      permissions: permissionsOptions.all,
    },
    welcomeBootcamp: {
      url: '/welcome/bootcamp',
      permissions: permissionsOptions.all,
    },
    welcomeSponsorship: {
      url: '/welcome/sponsorship',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcome: {
      url: '/sponsorship/[employerPartnerSlug]/welcome',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeOpportunity: {
      url: '/sponsorship/[employerPartnerSlug]/opportunity',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomePrograms: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/programs',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeProgram: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/program',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeMajor: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/major',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeInstitution: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/institution',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeGpa: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/gpa',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeGraduation: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/graduation',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeUSCitizen: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/us-citizen',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeAboutYou: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/about-you',
      permissions: permissionsOptions.all,
    },
    sponsorshipWelcomeRegister: {
      url: '/sponsorship/[employerPartnerSlug]/[sponsorshipOpportunitySlug]/register',
      permissions: permissionsOptions.all,
    },
    b2bPartnerWelcome: {
      url: '/welcome/[b2bPartner]',
      permissions: permissionsOptions.all,
    },
    b2bRegister: {
      url: '/welcome/register',
      permissions: permissionsOptions.all,
    },
    chooseNewPassword: {
      url: '/welcome/choose-password',
      permissions: permissionsOptions.all,
    },
    demoEmployeeApply: {
      caption: 'Apply',
      icon: signaturePenIcon,
      iconActive: signaturePenActiveIcon,
      permissions: permissionsOptions.customer,
      url: '/demo/apply',
    },
    applicantSponsorshipApplications: {
      caption: 'Applications',
      icon: checkboxIcon,
      iconActive: checkboxActiveIcon,
      permissions: permissionsOptions.customer,
      url: '/my-applications',
    },
    applicantSponsorshipProfile: {
      url: '/my-profile',
      permissions: permissionsOptions.customer,
      caption: 'My Profile',
      icon: trackChangesIcon,
      iconActive: trackChangesActiveIcon,
    },
  },
});

export default routes;
