import { AnalyticsDestination } from '../types/analytics';

import posthog from 'posthog-js';
import TagManager from 'react-gtm-module';

type AdditionalMetadata = {
    [key: string]: any;
};

type EventMetadata = {
    route?: string;
    object?: string;
    htmlType?: string;
    api?: string;
    underwriteDecision?: string;
    fundingPartner?: string;
    href?: string;
    text?: string;
    application?: string;
};

/**
 * Send an event with metadata to either Google Tag Manager / Posthog.
 *
 * @param {string} eventName - Unique name of the event in camelCase.
 * @param {EventMetadata & AdditionalMetadata} [eventMetadata] - Additional data payload captured with the event. The metadata can include predefined fields such as route, object, and application, as well as any number of additional fields as defined in AdditionalMetadata.
 * @param {AnalyticsDestination[]} destinations - Options to specify the destinations where the event should be sent. By default, events are sent to both Google Tag Manager (gtm) and Posthog (posthog).
 */
const triggerEvent = (eventName: string, eventMetadata?: EventMetadata & AdditionalMetadata, destinations: AnalyticsDestination[] = [ AnalyticsDestination.GTM, AnalyticsDestination.POSTHOG ]): void => {
  if (destinations?.includes(AnalyticsDestination.GTM)) TagManager.dataLayer({ 'dataLayer': { 'event': `portal.${eventName}`, ...eventMetadata }});

  if (destinations?.includes(AnalyticsDestination.POSTHOG)) posthog.capture(eventName, { ...eventMetadata });
};

export default triggerEvent;
