import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { DIM_FALLBACK_ERROR_MESSAGE } from '../../../constants/federalRepayments/dimErrors';
import {
  DIM_ERROR_EVENT_MESSAGES,
  DIM_ERROR_EVENTS,
  DIMEvent,
} from '../../../constants/federalRepayments/dimEvents';
import useAlert from '../../../hooks/useAlert';
import backend from '../../../lib/backend';
import { isDIMErrorEvent } from '../../../lib/federalRepayments/dimUtils';
import { borrowerAccountProfilesAtom } from '../../../state/atoms/borrowerAccountProfiles';
import spinwheelActiveModal from '../../../state/atoms/spinwheelActiveModal';
import spinwheelSessionToken from '../../../state/atoms/spinwheelSessionToken';
import userStateNeedsReset from '../../../state/atoms/userStateNeedsReset';
import { useUser } from '../../../state/user';
import { DIM_CONTAINER_IDS, DIM_NAMES, REPAYMENT_MODAL_STEPS } from '../../../types/spinwheelModals';
import ExternalWidgetModal from '../../dataDisplay/ExternalWidgetModal';

const SpinwheelIdentityConnectModal: FunctionComponent = () => {
  const user = useUser();
  const { showErrorAlert } = useAlert();
  const setUserStateResetNeeded = useSetRecoilState(userStateNeedsReset);
  const setActiveModal = useSetRecoilState(spinwheelActiveModal);
  const setBorrowerAccountProfiles = useSetRecoilState(borrowerAccountProfilesAtom);
  const [ isComponentMounted, setIsComponentMounted ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const sessionToken = useRecoilValue(spinwheelSessionToken);

  /**
   * DIM Callbacks definition
   */

  /**
   * Callback when DIM successfully completes its operation.
   * Triggers user state reset and fetches updated user data.
   */
  const onSuccess = useCallback(async () => {
    setIsLoading(true);

    // Fire request to get user data
    const customerId = user?.contents?.customerId;

    try {
      const borrowerProfiles = await backend.fetchSpinwheelIdentityData({ customerId });
      setBorrowerAccountProfiles(borrowerProfiles);
      user.refresh();
    } catch (error) {
      showErrorAlert(DIM_FALLBACK_ERROR_MESSAGE);
      setActiveModal(null);

      return;
    }

    // Stop loading state
    setIsLoading(false);

    // Switch modal to Loan Servicer modal
    setActiveModal(REPAYMENT_MODAL_STEPS.SELECT_LOAN_SERVICER_ACCOUNT);
  }, [ setActiveModal, setBorrowerAccountProfiles, showErrorAlert, user ]);

  /**
   * Callback when the DIM is loaded.
   */
  const onLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  /**
   * Callback for when the user exits the DIM.
   */
  const onExit = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  /**
   * Handles events and error events emitted by the Spinwheel DIM.
   */
  const onEvent = useCallback((eventPayload: DIMEvent) => {
    const eventName = eventPayload?.eventName;

    // If the event name corresponds to a dim error event, then handle the event as an error event
    if (isDIMErrorEvent(eventName)) {
      showErrorAlert(DIM_ERROR_EVENT_MESSAGES?.[eventName] ?? DIM_FALLBACK_ERROR_MESSAGE);

      // As soon as the identity connection fails (IDENTITY_NOT_CONNECTED), we redirect the user to the manual verification modal (KBA)
      if (eventName === DIM_ERROR_EVENTS.IDENTITY_NOT_CONNECTED) setActiveModal(REPAYMENT_MODAL_STEPS.KBA_VERIFICATION);
    }
  }, [ setActiveModal, showErrorAlert ]);

  /**
   * Callback for handling errors within the DIM.
   * Logs error details for troubleshooting.
   */
  const onError = useCallback(() => {
    showErrorAlert(DIM_FALLBACK_ERROR_MESSAGE);
    setIsLoading(false);
    // TODO add handler logic on fail, SSP-2138
  }, [showErrorAlert]);

  /**
   * Callback for resize events in the DIM.
   */
  const onResize = useCallback(() => {}, []);

  useEffect(() => setIsComponentMounted(true), []);

  // Effect for initializing Spinwheel DIM API
  useEffect(() => {
    if (!user.isLoaded) return console.debug('Waiting for user to be loaded');
    if (!sessionToken) return console.error('DIM could not be loaded, no session token provided');
    if (!isComponentMounted) return console.debug('Waiting for container DOM initialization');

    // Spinwheel instance is from their script
    // @ts-ignore
    const SpinwheelApi = Spinwheel;

    // Spinwheel script initialized
    const handler = SpinwheelApi.create({
      containerId: DIM_CONTAINER_IDS.SPINWHEEL_IDENTITY_CONNECT,
      dropinConfig: {
        module: DIM_NAMES.IDENTITY_CONNECT,
        token: sessionToken,
      },
      onSuccess,
      onLoad,
      onExit,
      onEvent,
      onError,
      onResize,
    });

    handler.open();
  }, [ user.isLoaded, isComponentMounted, onError, onEvent, onExit, onLoad, onResize, onSuccess, sessionToken, setActiveModal, setUserStateResetNeeded ]);

  return (
    <>
      <ExternalWidgetModal height={620} isLoading={isLoading} width={400}>
        <div id={DIM_CONTAINER_IDS.SPINWHEEL_IDENTITY_CONNECT} style={{ height: '100%' }}></div>
      </ExternalWidgetModal>
    </>

  );
};

export default SpinwheelIdentityConnectModal;
