/* eslint-disable no-restricted-globals */
import getConfig from 'next/config';

import {
  parseEnvBoolean,
  parseEnvFeature,
  parseEnvNumber,
  parseEnvString,
} from './lib';

const { publicRuntimeConfig } = getConfig() || {};

/**
 * Holds parsed environment variables and feature flags
 *
 * @note This is the only file that should interact with the global `process.env`
 */
const env = {
  feature: {
    ALLOW_MULTI_PRODUCT:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_ALLOW_MULTI_PRODUCT',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_ALLOW_MULTI_PRODUCT ?? process.env.NEXT_PUBLIC_FEATURE_ALLOW_MULTI_PRODUCT,
      ),
    BLOCK_ADJUSTMENT_DISBURSEMENT_FINWISE_SYNC:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_BLOCK_ADJUSTMENT_DISBURSEMENT_FINWISE_SYNC',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_BLOCK_ADJUSTMENT_DISBURSEMENT_FINWISE_SYNC ?? process.env.NEXT_PUBLIC_FEATURE_BLOCK_ADJUSTMENT_DISBURSEMENT_FINWISE_SYNC,
      ),
    CHANGE_REQUEST_MODE:
      parseEnvString(
        'NEXT_PUBLIC_FEATURE_CHANGE_REQUEST_MODE',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_CHANGE_REQUEST_MODE ?? process.env.NEXT_PUBLIC_FEATURE_CHANGE_REQUEST_MODE,
        'MAINTENANCE',
      ),
    CANCEL_SYNCS_ENABLED:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_CANCEL_SYNCS_ENABLED',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_CANCEL_SYNCS_ENABLED ?? process.env.NEXT_PUBLIC_FEATURE_CANCEL_SYNCS_ENABLED,
      ),
    D2C_ENABLED:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_D2C_ENABLED',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_D2C_ENABLED ?? process.env.NEXT_PUBLIC_FEATURE_D2C_ENABLED,
      ),
    EMPLOYER_PARTNER_FLOW_ENABLED_USERS:
      parseEnvString(
        'NEXT_PUBLIC_FEATURE_EMPLOYER_PARTNER_FLOW_ENABLED_USER_EMAILS',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_EMPLOYER_PARTNER_FLOW_ENABLED_USER_EMAILS ?? process.env.NEXT_PUBLIC_FEATURE_EMPLOYER_PARTNER_FLOW_ENABLED_USER_EMAILS,
        '',
      ),
    CLASP_REDIRECT_URL:
      parseEnvString(
        'NEXT_PUBLIC_CLASP_REDIRECT_URL',
        publicRuntimeConfig?.NEXT_PUBLIC_CLASP_REDIRECT_URL ?? process.env.NEXT_PUBLIC_CLASP_REDIRECT_URL,
        '',
      ),
    APPLICANT_WELCOME_FLOW:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_APPLICANT_WELCOME_FLOW',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_APPLICANT_WELCOME_FLOW ?? process.env.NEXT_PUBLIC_FEATURE_APPLICANT_WELCOME_FLOW,
      ),
    VIEW_CHANGE_REQUEST_VISIBLE:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_VIEW_CHANGE_REQUEST_VISIBLE',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_VIEW_CHANGE_REQUEST_VISIBLE ?? process.env.NEXT_PUBLIC_FEATURE_VIEW_CHANGE_REQUEST_VISIBLE,
      ),
    DEV_OVERLAY:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_DEV_OVERLAY',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_DEV_OVERLAY ?? process.env.NEXT_PUBLIC_FEATURE_DEV_OVERLAY,
      ),
    METHODFI_REPAYMENT_ENABLED:
        parseEnvFeature(
          'NEXT_PUBLIC_FEATURE_METHODFI_REPAYMENT_ENABLED',
          publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_METHODFI_REPAYMENT_ENABLED ?? process.env.NEXT_PUBLIC_FEATURE_METHODFI_REPAYMENT_ENABLED,
        ),
    FLINKS_DEMO_MODE:
      parseEnvFeature(
        'NEXT_PUBLIC_FEATURE_FLINKS_DEMO_MODE',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_FLINKS_DEMO_MODE ?? process.env.NEXT_PUBLIC_FEATURE_FLINKS_DEMO_MODE,
      ),
    TRUEWORK_ENV:
      parseEnvString(
        'NEXT_PUBLIC_TRUEWORK_ENV',
        publicRuntimeConfig?.NEXT_PUBLIC_TRUEWORK_ENV ?? process.env.NEXT_PUBLIC_TRUEWORK_ENV,
      ),
    TRUEWORK_PUBLIC_API_KEY:
      parseEnvString(
        'NEXT_PUBLIC_TRUEWORK_PUBLIC_API_KEY',
        publicRuntimeConfig?.NEXT_PUBLIC_TRUEWORK_PUBLIC_API_KEY ?? process.env.NEXT_PUBLIC_TRUEWORK_PUBLIC_API_KEY,
      ),
    USE_COMMENT_FIELD:
        parseEnvFeature(
          'NEXT_PUBLIC_FEATURE_USE_COMMENT_FIELD',
          publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_USE_COMMENT_FIELD ?? process.env.NEXT_PUBLIC_FEATURE_USE_COMMENT_FIELD,
        ),
    MANUAL_SPONSORSHIP_AGREEMENT_PROCESS_USER_EMAILS:
      parseEnvString(
        'NEXT_PUBLIC_FEATURE_MANUAL_SPONSORSHIP_AGREEMENT_PROCESS_USER_EMAILS',
        publicRuntimeConfig?.NEXT_PUBLIC_FEATURE_MANUAL_SPONSORSHIP_AGREEMENT_PROCESS_USER_EMAILS ?? process.env.NEXT_PUBLIC_FEATURE_MANUAL_SPONSORSHIP_AGREEMENT_PROCESS_USER_EMAILS,
        '',
      ),
  },
  var: {
    API_URL_EXTERNAL:
      parseEnvString(
        'NEXT_PUBLIC_API_URL_EXTERNAL',
        publicRuntimeConfig?.NEXT_PUBLIC_API_URL_EXTERNAL ?? process.env.NEXT_PUBLIC_API_URL_EXTERNAL,
      ),
    API_URL_INTERNAL:
      parseEnvString(
        'NEXT_PUBLIC_API_URL_INTERNAL',
        process.env.NEXT_PUBLIC_API_URL_INTERNAL,
      ),
    FLINKS_URL_BASE:
      parseEnvString(
        'NEXT_PUBLIC_FLINKS_URL_BASE',
        publicRuntimeConfig?.NEXT_PUBLIC_FLINKS_URL_BASE ?? process.env.NEXT_PUBLIC_FLINKS_URL_BASE,
      ),
    METHODFI_ELEMENTS_API_ENV:
        parseEnvString(
          'NEXT_PUBLIC_METHODFI_ELEMENTS_API_ENV',
          publicRuntimeConfig?.NEXT_PUBLIC_METHODFI_ELEMENTS_API_ENV ?? process.env.NEXT_PUBLIC_METHODFI_ELEMENTS_API_ENV,
        ),
    FRONTEND_BASE_URL:
      parseEnvString(
        'NEXT_PUBLIC_FRONTEND_BASE_URL',
        publicRuntimeConfig?.NEXT_PUBLIC_FRONTEND_BASE_URL ?? process.env.NEXT_PUBLIC_FRONTEND_BASE_URL,
        'http://localhost:3000',
      ),
    ENV:
      parseEnvString(
        'NEXT_PUBLIC_ENV',
        publicRuntimeConfig?.NEXT_PUBLIC_ENV ?? process.env.NEXT_PUBLIC_ENV,
        '',
      ),
    GTM_ID:
      parseEnvString(
        'NEXT_PUBLIC_GTM_ID',
        process.env.NEXT_PUBLIC_GTM_ID,
        '',
      ),
    MAINTENANCE_MODE:
      parseEnvBoolean(
        'NEXT_PUBLIC_MAINTENANCE_MODE',
        process.env.NEXT_PUBLIC_MAINTENANCE_MODE,
      ),
    MAX_FUNDING_AMOUNT:
      parseEnvNumber(
        'NEXT_PUBLIC_MAX_FUNDING_AMOUNT',
        process.env.NEXT_PUBLIC_MAX_FUNDING_AMOUNT,
      ),
    MIN_FUNDING_AMOUNT:
      parseEnvNumber(
        'NEXT_PUBLIC_MIN_FUNDING_AMOUNT',
        process.env.NEXT_PUBLIC_MIN_FUNDING_AMOUNT,
      ),
    NODE_ENV:
      parseEnvString(
        'NODE_ENV',
        process.env.NODE_ENV,
      ),
    ORIGIN_BASE_URL: parseEnvString(
      'NEXT_PUBLIC_ORIGIN_BASE_URL',
      publicRuntimeConfig?.NEXT_PUBLIC_ORIGIN_BASE_URL ?? process.env.NEXT_PUBLIC_ORIGIN_BASE_URL,
      '',
    ),
    POSTHOG_KEY:
      parseEnvString(
        'NEXT_PUBLIC_POSTHOG_KEY',
        publicRuntimeConfig?.NEXT_PUBLIC_POSTHOG_KEY ?? process.env.NEXT_PUBLIC_POSTHOG_KEY,
      ),
    POSTHOG_HOST:
      parseEnvString(
        'NEXT_PUBLIC_POSTHOG_HOST',
        publicRuntimeConfig?.NEXT_PUBLIC_POSTHOG_HOST ?? process.env.NEXT_PUBLIC_POSTHOG_HOST,
      ),
    PRESET_SUPERSET_DOMAIN:
      parseEnvString(
        'NEXT_PUBLIC_PRESET_SUPERSET_DOMAIN',
        publicRuntimeConfig?.NEXT_PUBLIC_PRESET_SUPERSET_DOMAIN ?? process.env.NEXT_PUBLIC_PRESET_SUPERSET_DOMAIN,
      ),
    SENTRY_DSN:
      parseEnvString(
        'SENTRY_DSN || NEXT_PUBLIC_SENTRY_DSN',
        process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
        'https://bba02fb81ec04da6a6fa80ffbafca79f@o877038.ingest.sentry.io/6089431',
      ),
    SENTRY_ENV:
      parseEnvString(
        'SENTRY_ENV || NEXT_PUBLIC_SENTRY_ENV',
        process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV,
        'dev',
      ),
    LOCAL_TESTING_MODE:
      parseEnvBoolean(
        'NEXT_PUBLIC_LOCAL_TESTING_MODE',
        publicRuntimeConfig?.NEXT_PUBLIC_LOCAL_TESTING_MODE ?? process.env.NEXT_PUBLIC_LOCAL_TESTING_MODE,
      ),
    ENABLE_CYPRESS_VIDEOS:
      parseEnvBoolean(
        'ENABLE_CYPRESS_VIDEOS',
        publicRuntimeConfig?.ENABLE_CYPRESS_VIDEOS ?? process.env.ENABLE_CYPRESS_VIDEOS,
      ),
    ENABLE_CYPRESS_SCREENSHOTS:
      parseEnvBoolean(
        'ENABLE_CYPRESS_SCREENSHOTS',
        publicRuntimeConfig?.ENABLE_CYPRESS_SCREENSHOTS ?? process.env.ENABLE_CYPRESS_SCREENSHOTS,
      ),
  },
} as const;

export default env;
