import React, { FunctionComponent } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';

import routes from '../../lib/routes';
import { eslApplicationSubmittedModalState } from '../../state/atoms/eslApplicationSubmittedModalState';
import { sponsorshipApplicationAtom } from '../../state/atoms/sponsorshipApplication';
import palette from '../../styles/palette';

const EslApplicationSubmittedModal: FunctionComponent = () => {
  const [ isOpen, setIsOpen ] = useRecoilState(eslApplicationSubmittedModalState);
  const sponsorshipApplication = useRecoilValue(sponsorshipApplicationAtom);

  const handleCloseModal = () => setIsOpen(false);

  if (!sponsorshipApplication) return null;

  return (
    <Dialog
      onClose={handleCloseModal}
      open={isOpen}
    >
      <Box id='application-submit-modal' sx={{ px: 3, py: 2 }}>
        <DialogTitle id='application-submit-title'>
          <Typography align='center' variant='h2'>
            Great work! Your Application has Been Received! 🥳
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='application-submit-description'>
            <Typography align='center' variant='subtitle1'>
              {sponsorshipApplication.partner_name} will review your application and reach out to you for next steps.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='center' width='100%'>
            <Button
              href={routes.internal.dashboard.url}
              sx={
                {
                  backgroundColor: palette.pink.dark,
                  '&:hover': {
                    backgroundColor: palette.pink.light,
                  },
                }
              }
              variant='contained'
            >
              Back to my dashboard
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EslApplicationSubmittedModal;
