import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import useAlert from '../../../hooks/useAlert';
import backend from '../../../lib/backend';
import env from '../../../lib/env';
import applicationBackdropState from '../../../state/atoms/applicationBackdropState';
import repaymentActiveModal from '../../../state/atoms/repaymentModalOpen';
import { useUser } from '../../../state/user';
import { ConnectEventPayload, ErrorEventPayload, EventPayload } from '../../../types/methodFi/types';

import { useMethod } from 'react-method-elements';

const MethodFiElementModal: FunctionComponent = () => {
  const setRepaymentActiveModal = useSetRecoilState(repaymentActiveModal);
  const setIsApplicationBackdropOpen = useSetRecoilState(applicationBackdropState);
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const user = useUser();

  const methodAPI = useMethod({
    env: env.var.METHODFI_ELEMENTS_API_ENV,
    onEvent: (payload: unknown) => {
      const event = payload as EventPayload;
      console.debug('MethodFi element EVENT:', event);
    },
    onSuccess: async (payload: unknown) => {
      const event = payload as ConnectEventPayload;
      console.debug('MethodFi element SUCCESS:', event);

      try {
        await backend.storeRepaymentExternalId({ external_id: event.entity_id });

        await backend.fetchAndStoreRepaymentAccounts();
        showSuccessAlert('Successfully connected to repayment platform.');
        setRepaymentActiveModal(null);
        user.refresh();
      } catch (error) {
        showErrorAlert('Failed to connect to repayment platform. Please try again later.');
      }
    },
    onError: (payload: unknown) => {
      const event = payload as ErrorEventPayload;
      console.debug('MethodFi element ERROR:', event);
      setRepaymentActiveModal(null);
    },
    onExit: (payload: unknown) => {
      const event = payload as EventPayload;
      console.debug('MethodFi element EXIT:', event);
      setRepaymentActiveModal(null);
    },
    onOpen: (payload: unknown) => {
      const event = payload as EventPayload;
      console.debug('MethodFi element OPEN:', event);
      setIsApplicationBackdropOpen(false);
    },
  });

  const initializeModal = useCallback(async () => {
    if (!methodAPI) return;
    const token = await backend.fetchRepaymentSessionToken();
    methodAPI.open(token);
  }, [methodAPI]);

  useEffect(() => {
    setIsApplicationBackdropOpen(true);
    initializeModal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ initializeModal, setIsApplicationBackdropOpen ]);

  // Ensure method is loaded.
  if (!methodAPI) return null;

  return <div data-cy={'methodfi-connect-element'}></div>;
};

export default MethodFiElementModal;
