import React, { FunctionComponent } from 'react';
import { useSetRecoilState } from 'recoil';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import repaymentActiveModal from '../../../state/atoms/repaymentModalOpen';
import { MethodFIRepaymentModalSteps } from '../../../types/methodFiModals';

const MethodFiManageLoanAccounts: FunctionComponent = () => {
  const setRepaymentActiveModal = useSetRecoilState(repaymentActiveModal);

  return (
    <Dialog id='manage-loan-accounts-dialog' onClose={() => setRepaymentActiveModal(null)} open={true}>
      <Box mx={3} my={3}>
        <DialogTitle>Update Connected Student Loan Accounts</DialogTitle>
        <DialogContent>
          Please note, at least one connected student loan account is required to facilitate the benefits outlined in your repayment agreement.
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='space-evenly' width='100%'>
            <Button
              disabled={true} // TODO enable after having whole flow connected SSP-3072
              id='manage-loan-accounts-dialog-remove-action' //TODO Add action on remove
              onClick={() => {}}
              size='small'
              sx={{ m: 1 }}
              variant='outlined'
            >
              Remove all connected accounts
            </Button>
            <Button
              autoFocus
              id='manage-loan-accounts-dialog-view-action'
              onClick={() => setRepaymentActiveModal(MethodFIRepaymentModalSteps.METHODFI_ELEMENT)}
              size='small'
              sx={{ m: 1 }}
              variant='contained'
            >
              View connected accounts
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default MethodFiManageLoanAccounts;
