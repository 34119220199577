import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';

import repaymentActiveModal from '../../../state/atoms/repaymentModalOpen';
import { MethodFIRepaymentModalSteps } from '../../../types/methodFiModals';

import MethodFiElementModal from './MethodFiElementModal';
import MethodFiManageLoanAccounts from './MethodFiManageLoanAccounts';

/**
 * MethodFiModalContainer is a React component that acts as a container for various MethodFi modals.
 * It uses Recoil to manage the state of the active modal and conditionally renders the appropriate MethodFi
 * modal component based on the current active modal's state. This container ensures that the correct modal
 * is displayed to the user based on their interaction with the MethodF functionalities.
 *
 * All necessary MethodFi modal components should be included in this container.
 *
 * @component
 */
const MethodFiModalContainer: FunctionComponent = () => {
  const activeModal = useRecoilValue(repaymentActiveModal);

  if (!activeModal) return null;

  return (
    <>
      {activeModal === MethodFIRepaymentModalSteps.MANAGE_CONNECTED_LOAN_ACCOUNTS && <MethodFiManageLoanAccounts />}
      {activeModal === MethodFIRepaymentModalSteps.METHODFI_ELEMENT && <MethodFiElementModal />}
    </>
  );
};

export default MethodFiModalContainer;
